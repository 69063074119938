import AashiHeadshot from "./AashiHeadshot.JPG"
import { useState, useEffect, useRef } from "react";
import './App.css';
import EonLogo from "./eonlogo.png"
import FordLogo from "./fordlogo.png"
import MDTLogo from "./mdtlogo.png"
import ScentsationalLogo from "./scentsational-logo.png"
import CUTCLogo from "./cutc-logo.png"
import CRTLYLogo from "./ctrlylogo.png"
import MOHLogo from "./moh-logo.png"
import WIELogo from "./wie-logo.png"
import DevIcon from "./coding-icon.png"
import BakingIcon from "./baking-icon.png"
import GithubIcon from "./github-icon.png"
import LinkIcon from "./link-icon.png"
import GreenHabitSS from "./green-habit-ss.png"
import RecipeGenSS from "./recipe-gen-ss.png"
import SydeProfileSS from "./syde-profile-ss.png"
import Typed from "react-typed"

function App() {

  const funFacts = {
    "baking": <div>Like many others, I started baking in early 2020. I've tried out tons of recipes ranging from breads to desserts, from a variety of cultures. Check out my <span className="bold"><a href="https://aashi-s.github.io/recipeGenerator/" target="_blank" rel="noreferrer">Recipe Generator</a></span> to see some of my favourite bakes!</div>,
    "development": <div>I'm always looking to learn something new in the world of software, whether it's a new tool I can leverage to make my work efficient, or a process to make frontend projects more user friendly!</div>
  }

  const experiences = [
    {
      logo: FordLogo,
      title: "Software Developer",
      location: "Ford Motor Company",
      desc: "Enhanced performance indicator coverage on upcoming infotainment platforms by 35% with automated Python tests. Developed on-device and server-side tooling for embedded system performance diagnostics. Conducted Android testing on multiple engine control units, leveraging QNX and ADB."
    },
    {
      logo: MDTLogo,
      title: "Cloud Developer",
      location: "Mobile Data Technologies",
      desc: "Carried out migration of microservice communication from UDP to MQTT, reducing network traffic by 60%. Used Java and Spring Boot to develop APIs for microservices, enabling seamless integration with front-end applications. Implemented comprehensive unit, functional, and integration test suites, cutting post-deployment issues by 70%."
    },
    {
      logo: EonLogo,
      title: "Fullstack Web Developer",
      location: "Eon Media",
      desc: "Built Elasticsearch and Mongoose queries to serve data to a React web app through RESTful API calls. Expedited process of parsing JSONs from S3 buckets to accommodate production needs. Maintained services deployed on an Nginx server, and led weekly presentations to clients to gather feedback."
    },
    {
      logo: MOHLogo,
      title: "Information Management Analyst",
      location: "Ministry of Health Ontario",
      desc: "Spearheaded a redesign initiative to update a policy item used by hospitals across Ontario to manage project expenditures. This consisted of leading user studies with project team leads and clients to ensure changes would streamline information processes."
    },
    {
      logo: WIELogo,
      title: "Events Director",
      location: "Women in Engineering UW",
      desc: "Organized networking events and workshops to empower over 100 engineering students. Collaborated with social and outreach teams to increase turnout by 15%."
    },
    {
      logo: CRTLYLogo,
      title: "Web Developer",
      location: "CTRL + Y Designathon",
      desc: "Collaborated with other developers to build a website for the firt iteration of the designathon. Created a space to allow sponsors and attendees to learn more about the event and get involved."
    },
    {
      logo: CUTCLogo,
      title: "Developer",
      location: "Canadian Undergraduate Technology Conference",
      desc: "Developed a landing page and website for the 2023 conference using Vue, with 80 000 expected visitors. Continually supported the development team through any updates to the conference."
    },
    {
      logo: ScentsationalLogo,
      title: "Co-founder",
      location: "Scentsational",
      desc: "Co-founded handmade cosmetics business, selected as provincial finalists among over 100 teams. Developed company website using HTML and CSS to drive e-commerce revenue and engage customers."
    }
  ]

  const projects = [
    {
      name: "Recipe Generator",
      about: "Compiled a list of my favourite baking recipes, which users can select a random recipe from. Designed and developed a fully responsive React app to display these recipes, and used hooks to manage state for the randomized effect.",
      github: "https://github.com/aashi-s/recipeGenerator",
      link: "https://aashi-s-recipe-generator.netlify.app/",
      screenshot: RecipeGenSS
    },
    {
      name: "SYDE Profile",
      about: "Collected data from the Systems Design Engineering class of 2026 ranging from topics about students' lifestyle to academics. Displayed this data on an interactive web app built using React and Chart.js, which has over 75 000 viewers.",
      github: "https://github.com/SYDE-26/1A-Class-Profile-Website",
      link: "https://syde26.life/",
      screenshot: SydeProfileSS
    },
    {
      name: "Green Habit",
      about: "As a submission for Hack the Earth, prototyped a web application, allowing users to develop habits through reminders sent at customized intervals.",
      github: "https://github.com/neethunsr/Hack-the-Earth",
      link: "https://greenhabit-hacktheearth.netlify.app/",
      screenshot: GreenHabitSS
    },
  ]

  const [current, setCurrent] = useState("about")
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768)
  const [expandedExperience, setExpandedExperience] = useState(new Array(experiences.length).fill(false))
  const [expandedProjects, setExpandedProjects] = useState(new Array(projects.length).fill(false))
  const [index, setIndex] = useState(0)
  const timeoutRef = useRef(null)
  const experienceRef = useRef(null)

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 768)
  }
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  })

  const toggleExpandedExperience = (index) => {
    const copyExpanded = [...expandedExperience]
    copyExpanded[index] = !copyExpanded[index]
    setExpandedExperience(copyExpanded)
  }

  const toggleExpandedProjects = (index) => {
    const copyExpanded = [...expandedProjects]
    copyExpanded[index] = !copyExpanded[index]
    setExpandedProjects(copyExpanded)
  }

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === 2 ? 0 : prevIndex + 1
        ),
      isDesktop ? 2500 : 2000
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="app">
      {isDesktop ?
        <div className="desktop">
          <div className="left-rectangle"></div>
          <div className="header">
            <img src={AashiHeadshot} className="headshot" alt="headshot" />
            <div className="name">Aashi Shah</div>
            <div className="job">
              <Typed
                strings={[
                  "Developer",
                  "Systems Design Engineering Student @ University of Waterloo",
                  "Baking enthusiast",
                ]}
                typeSpeed={60}
                backSpeed={75}
                loop
              />
            </div>
            <div className="links">
              <div>
                <a style={{ color: "var(--color-light)" }} href="https://drive.google.com/file/d/1zhN4-22nL-6Z-EMPEhj7GcVpluX5bwhh/view?usp=sharing" target="_blank" rel="noreferrer" className="link-option"><div>Resume</div></a>
                <a style={{ color: "var(--color-light)" }} href="https://www.linkedin.com/in/aashishah-/" target="_blank" rel="noreferrer"><div className="link-option">LinkedIn</div></a>
                <a style={{ color: "var(--color-light)" }} href="https://github.com/aashi-s" target="_blank" rel="noreferrer"><div className="link-option">GitHub</div></a>
              </div>
            </div>
          </div>
          <div className="info">
            <div>
              <div className="info-selector">
                <div className={current === "about" ? "info-tab-current" : "info-tab"} onClick={() => setCurrent("about")}>About</div>
                <div className={current === "experience" ? "info-tab-current" : "info-tab"} onClick={() => setCurrent("experience")}>Experience</div>
                <div className={current === "projects" ? "info-tab-current" : "info-tab"} onClick={() => setCurrent("projects")}>Projects</div>
              </div>
              <div className={current === "about" ? "hidden" : "note"}>(select a card to see more!)</div>
            </div>
            <div className={current === "about" ? "info-container-about" : "hidden"}>
              <div className="about-recently">
                <div>Recently...</div>
                <div className="slideshow">
                  <div
                    className="slideshowSlider"
                    style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
                  >
                    <div className="slide-0">
                      <div className="slide-content">
                        <div className="icon-circle"><img src={FordLogo} alt="Ford logo" /></div>
                        <div style={{ flexDirection: "column" }}>
                          <div className="recent-position">Software Developer</div>
                          <div className="recent-location">Ford Motor Company</div>
                        </div>
                        <div>see the <span className="bold" onClick={() => setCurrent("experience")}>Experience</span> tab for more details!</div>
                      </div>
                    </div>
                    <div className="slide-1">
                      <div className="slide-content">
                        <div className="icon-circle"><img src={WIELogo} alt="WIE Logo" /></div>
                        <div style={{ flexDirection: "column" }}>
                          <div className="recent-position">Events Director</div>
                          <div className="recent-location">Women in Engineering @ UW</div>
                        </div>
                        <div>see the <span className="bold" onClick={() => setCurrent("experience")}>Experience</span> tab for more details!</div>
                      </div>
                    </div>
                    <div className="slide-2">
                      <div className="slide-content">
                        <div className="icon-circle"><img src={CRTLYLogo} alt="CTRL + Y logo" /></div>
                        <div style={{ flexDirection: "column" }}>
                          <div className="recent-position">Web Developer</div>
                          <div className="recent-location">CTRL + Y Designathon</div>
                        </div>
                        <div>see the <span className="bold" onClick={() => setCurrent("experience")}>Experience</span> tab for more details!</div>
                      </div>
                    </div>
                  </div>

                  <div className="slideshowDots">
                    <div
                      key="0"
                      className={`slideshowDot${index === 0 ? " active" : ""}`}
                      onClick={() => setIndex(0)}
                    ></div>
                    <div
                      key="1"
                      className={`slideshowDot${index === 1 ? " active" : ""}`}
                      onClick={() => setIndex(1)}
                    ></div>
                    <div
                      key="2"
                      className={`slideshowDot${index === 2 ? " active" : ""}`}
                      onClick={() => setIndex(2)}
                    ></div>
                  </div>
                </div>


              </div>
              <div className="about-dev-passion">
                <div className="icon-circle medium"><img src={DevIcon} className="about-icon" alt="icon for development" /></div>
                {funFacts["development"]}
              </div>
              <div className="about-baking-passion">
                <div className="icon-circle medium"><img src={BakingIcon} className="about-icon" alt="icon for baking" /></div>
                {funFacts["baking"]}
              </div>

            </div>
            <div className={current === "experience" ? "info-container-experience" : "hidden"}>
              {experiences.map((item, index) => (
                <div key={index} className={expandedExperience[index] ? "experience-card-expanded" : "experience-card"} onClick={() => toggleExpandedExperience(index)}>
                  <div>
                    <div className="icon-circle">
                      <img src={item.logo} alt={item.location} />
                    </div>
                  </div>
                  <div>
                    {item.title}
                  </div>
                  <div>
                    {item.location}
                  </div>
                  <div className="experience-blurb">
                    {item.desc}
                  </div>
                </div>
              ))}

            </div>
            <div className={current === "projects" ? "info-container-projects" : "hidden"}>
              {projects.map((item, index) => (
                <div key={index} className={expandedProjects[index] ? "project-card-expanded" : "project-card"} onClick={() => toggleExpandedProjects(index)}>
                  <img className="project-ss" src={item.screenshot} alt={`${item.name} screenshot`} />
                  <div className="project-header">
                    <div className="project-name">{item.name}</div>
                    <a href={item.github} target="_blank" rel="noreferrer"><img className="project-github" src={GithubIcon} alt="github logo" /></a>
                    <a href={item.link} target="_blank" rel="noreferrer"><img className="project-link" src={LinkIcon} alt="link icon" /></a>
                  </div>
                  <div className="project-desc">{item.about}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        :
        <div className="mobile">
          <div className="mobile-links">
            <div>
              <a href="https://drive.google.com/file/d/1v-Tcq-ojwy3OX8m66HAjbuTuiK6pDDti/view?usp=sharing" target="_blank" rel="noreferrer" className="link-option"><div>Resume</div></a>
              <a href="https://www.linkedin.com/in/aashishah-/" target="_blank" rel="noreferrer"><div className="link-option">LinkedIn</div></a>
              <a href="https://github.com/aashi-s" target="_blank" rel="noreferrer"><div className="link-option">GitHub</div></a>
            </div>
          </div>
          <div className="mobile-header">
            <div className="mobile-rectangle"></div>
            <img src={AashiHeadshot} className="headshot" alt="headshot" />
            <div style={{ zIndex: "999" }}>
              <div className="name">Aashi Shah</div>
              <div className="job">
                <Typed
                  className="typed"
                  strings={[
                    "Developer",
                    "Systems Design Engineering Student @ University of Waterloo",
                    "Baking enthusiast",
                  ]}
                  typeSpeed={60}
                  backSpeed={75}
                  loop
                />
              </div>
            </div>
          </div>
          <div className="mobile-info-container-header">About</div>
          <div className="info-container-about">
            <div className="about-recently">
              <div>Recently...</div>
              <div className="slideshow">
                <div
                  className="slideshowSlider"
                  style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
                >
                  <div className="slide-0">
                    <div className="slide-content">
                      <div className="icon-circle recently"><img src={FordLogo} alt="Ford logo" /></div>
                    </div>
                  </div>
                  <div className="slide-1">
                    <div className="slide-content">
                      <div className="icon-circle recently"><img src={WIELogo} alt="WIE logo" /></div>
                    </div>
                  </div>
                  <div className="slide-2">
                    <div className="slide-content">
                      <div className="icon-circle recently"><img src={CRTLYLogo} alt="CTRL + Y logo" /></div>
                    </div>
                  </div>
                </div>
              </div>

              <div>see the <span className="bold" onClick={() => experienceRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>Experience</span> section for more details!</div>
            </div>
            <div className="about-baking-passion">
              <div className="icon-circle medium"><img src={BakingIcon} className="about-icon" alt="baking icon" /></div>
              {funFacts["baking"]}
            </div>
            <div className="about-dev-passion">
              <div className="icon-circle medium"><img src={DevIcon} className="about-icon" alt="development icon" /></div>
              {funFacts["development"]}
            </div>


          </div>
          <div className="mobile-info-container-header"><div>Experience</div> <div className="note">(select a card to see more!)</div></div>
          <div className="info-container-experience" ref={experienceRef}>
            {experiences.map((item, index) => (
              <div key={index} className={expandedExperience[index] ? "experience-card-expanded" : "experience-card"} onClick={() => toggleExpandedExperience(index)}>
                <div>
                  <div className="icon-circle">
                    <img src={item.logo} alt={item.location} />
                  </div>
                </div>
                <div>
                  {item.title}
                </div>
                <div>
                  {item.location}
                </div>
                <div className="experience-blurb">
                  {item.desc}
                </div>
              </div>
            ))}
          </div>
          <div className="mobile-info-container-header"><div>Projects</div> <div className="note">(select a card to see more!)</div></div>
          <div className="info-container-projects">
            {projects.map((item, index) => (
              <div key={index} className={expandedProjects[index] ? "project-card-expanded" : "project-card"} onClick={() => toggleExpandedProjects(index)}>
                <img className="project-ss" src={item.screenshot} alt={`${item.name} screenshot`} />
                <div className="project-header">
                  <div className="project-name">{item.name}</div>
                  <a href={item.github} target="_blank" rel="noreferrer"><img className="project-github" src={GithubIcon} alt="github logo" /></a>
                  <a href={item.link} target="_blank" rel="noreferrer"><img className="project-link" src={LinkIcon} alt="link icon" /></a>
                </div>
                <div className="project-desc">{item.about}</div>
              </div>
            ))}
          </div>
        </div>
      }
      <div className="footer">
        Designed and developed by Aashi Shah
      </div>
    </div>
  );
}

export default App;
